@import "primereact/resources/primereact.min.css";
@import "primeflex/primeflex.css";
@import "primeicons/primeicons.css";
@import "./assets/css/app/layout.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;700&display=swap');
// @import "../node_modules/prismjs/themes/prism-coy.css";
// @import "assets/css/demo/flags.css";

/* Fonts Imports */
@import url('https://fonts.googleapis.com/css?family=Comfortaa');
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Source Code Pro');
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Comfortaa', sans-serif !important; */
    /* font-family: 'Montserrat', sans-serif !important; */
    /* font-family: 'Roboto', sans-serif !important; */
    /* font-family: 'Source Code Pro', monospace !important; */
  }
  
  .comfortaa-font {
    font-family: 'Comfortaa', sans-serif !important;
  }
  
  .montserrat-font {
    font-family: 'Montserrat', sans-serif !important;
  }
  
  .font-source-code-pro {
    font-family: 'Source Code Pro', monospace !important;
  }
  
  .roboto-font {
    font-family: 'Roboto', sans-serif !important;
  }

  .c-shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  }
