html {
    font-size: 1rem;
    height: 100%;
}

body {
    font-family: var(--font-family);
    background-color: var(--surface-ground);
    color: var(--text-color);
    padding: 0;
    margin: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
//    font-family: 'Poppins', sans-serif;
}


.documentation i {
    background-color: var(--surface-card);
    color: #2196f3;
    font-family: Monaco,courier,monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 4px;
    letter-spacing: .5px;
    border-radius: 3px;
    font-weight: 600;
    margin: 0 2px;
}

a{
    text-decoration: none;
}

@media only screen and (min-width: 600px) {
    .dash-spin {
      width: 300;
      height: 300;
    }
  }